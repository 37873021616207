import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function NBFCCompliance() {
  /* Slider */
  // const NbfcSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `NBFC Compliance Filing`,
  //     content: `India's Best NBFC Compliance Filing Service`,
  //     image: "/imgs/registration/iec/iec-benefits.png",
  //     alt_tag: "Best Online NBFC Compliance Filing Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online NBFC Compliance Filing`,
  //     content: `Best NBFC Compliance Registration Service in Hosur`,
  //     image: "/imgs/registration/ngo/fcra-registration.png",
  //     alt_tag: "Best Online NBFC Compliance Filing Service in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online NBFC Compliance Filing`,
  //     content: `TODAYFILINGS, India's largest NBFC's Compliance service in india, can
  //     assist you with your business.`,
  //     image: "/imgs/business/sliders/pvt-slider.png",
  //     alt_tag: "Best Online NBFC Compliance in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `NBFC Compliance`,
    buyBtnLink: `#pricing-buy`,
    price: `19999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const NbfcAboutData = {
    header: `NBFC Compliance Filing`,
    sub_title: `Filing Online NBFC Compliance With TODAYFILINGS Experts.`,
    content_paragraph: [
      `Companies registered under the Companies Act 2013 are known
      as "NBFC's," or non-banking financial companies. They are
      engaged in the business of accepting deposits, advances, and
      loans as well as the acquisition of stock, shares, and
      bonds, as well as debentures and other government-issued
      assets. NBFC's are not allowed to operate their businesses
      without a Reserve Bank of India license.`,

      // ` After the Sahara case, the RBI
      // revised the regulations and compliance requirements for
      // NBFC's. Securitization of Standard Assets and Guidelines
      // for Private Placement are two of the important rules and
      // regulations.`,
    ],
  };
  /* Scroll Nav Data */
  const NbfcScrollId = [
    {
      id: `#nbfc-types`,
      heading: `NBFC Types`,
    },

    {
      id: `#return-types`,
      heading: `Types Of Returns`,
    },
    {
      id: `#compliance`,
      heading: `Annual Compliances`,
    },
    {
      id: `#checklist`,
      heading: `Checklist Of NBFC`,
    },
  ];
  /* Img Content Component Data */
  const NbfcIcData = {
    id: 'nbfc-types',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Types Of NBFC Compliances',
    image: '/imgs/business-plan.png',
    alt_tag: 'Section 8 Company Income Tax Compliance',
    Paragraph: `Following are the several categories of NBFC's, or
    non-banking financial companies, according to their
    liabilities and activities:`,
    points: [
      {
        head: `NBFCs based on Liabilities`,
        content: `NBFC's that accept deposits.`,
        icon: true,
      },
      {
        content: `Important Systematically (NBFC-ND-SI).`,
        icon: true,
      },
      {
        content: `NBFC's that do not accept deposits.`,
        icon: true,
      },
      {
        content: `Other Non-Deposit Holding Companies.`,
        icon: true,
      },
      {
        head: 'NBFCs based on Activities',
        content: `Company for Investment and Credit (ICC).`,
        icon: true,
      },
      {
        content: `The Infrastructure Finance Corporation(IFC).`,
        icon: true,
      },
      {
        content: `Company with Core Investments of Systemic Importance (CIC)`,
        icon: true,
      },
      {
        content: `NOFHC, an NBFC, stands for non-operative financial
      holding company.`,
        icon: true,
      },
      {
        content: `Microfinance Institutions (MFIs), or NBFCs.`,
        icon: true,
      },
      {
        content: `Non-Banking Financial Company Infrastructure Debt Fund (IDF-NBFC).`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const NbfcCfData = {
    id: 'return-types',
    // mt_div: "mt-5",
    // mt_img: "mt-5",
    header: 'Types of NBFC Returns',
    content: 'Returns From NBFCs Accepting Deposits',
    body: [
      {
        head: 'NBS-1',
        points: `For the aim of capturing financial data, such as
      components of assets and liabilities, profit and loss
      account, quarterly reports are necessary.`,
        icon: true,
      },
      {
        head: 'NBS-2',
        points: `Quarterly Return in accordance with prudential
      standards This return's objective is to gather data
      for many standards, including asset classification,
      NOF, capital adequacy, provisioning, and others.`,
        icon: true,
      },
      {
        head: 'NBS-3',
        points: `For information on statutory funds and investments in
      liquid states, quarterly Return on Liquid Assets and
      similar requirements are necessary.`,
        icon: true,
      },
      {
        head: 'NBS-4',
        points: `Critical metrics are returned annually. This return is
      being submitted in order to record the repayment
      status of the rejected NBFCs that are currently taking
      public deposits.`,
        icon: true,
      },
      {
        head: 'NBS-6',
        points: `This form must be presented as a Monthly Return on
      Picture to Capital Market by NBFC Deposit-taking with
      a value of the total assets of Rs. 100 crore or
      higher.`,
        icon: true,
      },
      {
        head: 'ALM Return',
        points: `NBFCs that hold public deposits with amounts greater
      than Rs. 20 crore or assets valued at more than Rs.
      100 crore are required to file these returns on a
      semi-annual basis.`,
        icon: true,
      },
      {
        points: `In order to file this return, the NBFC that accepts
      public deposits must provide an authorized Auditor's
      Report and an audited balance sheet`,
        icon: true,
      },
      {
        points: `Return related to branch details.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const NbfcCiData = {
    id: 'checklist',
    background: 'main-content',
    mt_div: 'mt-3',
    header: `Checklist For NBFC- D(Deposit-Taking)`,
    paragraph: `Similar to non-deposit NBFCs, NBFC-D (Deposit Taking)
    institutions are dependent on deposits approved by the
    corporation and are also required to provide a few
    annual returns by non-deposit NBFC`,
    points: [
      {
        head: `NBS-7`,
        content: `Quarterly report or statement that includes data on
        capital funds, the risk assets ratio, the
        risk-weighted asset, and other topics.`,
        icon: true,
      },
      {
        head: `NBS-2`,
        content: `ALM Returns are a monthly return on an important
        financial metric for the business or NBFCs.`,
        icon: true,
      },
      {
        head: `ALM Returns`,
        content: `A monthly short-term dynamic liquidity statement in NBS-ALM-1 format.`,
        icon: true,
      },
      {
        content: `Semi-Annual Statement of Structural Liquidity in
        NBS-ALM2 Format.`,
        icon: true,
      },
      {
        content: `Semi-Annual Statement of Interest Rate Sensitivity in
        NBS-ALM-3 Format.`,
        icon: true,
      },
      {
        head: `Branch Information Return`,
        content: `Quarterly performance on important financial metrics
        for non-deposit taking NBFCs with assets worth more
        than 50 crore rupees but less than 100 crore rupees.`,
        icon: true,
      },
      {
        content: `Non-deposit taking NBFCs with assets between Rs. 50
        crore and Rs. 100 crore are needed to report or file
        the following quarterly the name of the company, Net
        Owned Fund, address, and profit and loss for the last
        three years.`,
        icon: true,
      },
    ],
    image: '/imgs/business/dsc1-lib.png',
    alt_tag: 'Checklist for NBFC Compliance Registration',
  };
  var FAQ_data = [
    {
      header: 'How are funds raised by NBFC?',
      body: [
        {
          content: `In order to raise money, NBFC's typically take money from banks or
          sell company papers or paperwork to shared assets. The corporation
          will then lend these cash to small and medium-sized businesses,
          retail customers, etc.`,
          icon: false,
        },
      ],
    },

    {
      header: 'Is LIC a NBFC?',
      body: [
        {
          content: `While LIC of India is an NBFC, banks are BFCs, or banking and
          financial companies. While LIC offers a life insurance cover to
          the beneficiaries, the bank mostly deals with issues related to
          lending and depositing money.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What distinguishes an NBFC from an MFI?',
      body: [
        {
          content: `The term "NBFC," or "Non-Banking Financial Companies," refers to
          non-banking financial institutions that serve rural areas in ways
          similar to rural banks. MFIs are miniature scale account
          establishments that operate at a lower level than NBFC's and offer
          small loans to the general public or rural population.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can an NBFC offer a loan?',
      body: [
        {
          content: `Loans and credit facilities, retirement underwriting, planning,
          currency exchange, money markets, and merging business activities
          are all services that NBFC's can offer.`,
          icon: false,
        },
      ],
    },
    {
      header: 'In India, who oversees NBFCs?',
      body: [
        {
          content: `The Reserve Bank of India (RBI) oversees and controls every aspect
          of NBFC operations within the parameters of the Reserve Bank of
          India Act, 1934.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='NBFC Compliance'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={NbfcSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={NbfcAboutData} />

          <Counter />

          <ScrollNav scroll_data={NbfcScrollId} />

          <ImgContent item={NbfcIcData} />

          <ContentForm CFSection_data={NbfcCfData} />
          <section id='compliance' className='mt-5 mb-5'>
            <div className='container'>
              <div>
                <header className='section-header mt-5'>
                  <h2>NBFC's Annual Compliance</h2>
                  <div className='bar'></div>
                </header>

                <p>
                  In order to assure transactions for both its customers and the
                  government with lower risk factors through disclosure of the
                  annual statements and returns, NBFCs, or non-banking financial
                  companies, have improved and tightened up their yearly
                  compliance procedures.
                </p>
                <p>
                  It is required to file the yearly records and statements of
                  capital funds using the NBFC-ND-SI (Non-Deposit category).
                  Additionally new are the disclosure standards for capital
                  adequacy and liquidity. The company must maintain 15% capital
                  adequacy in accordance with the 2007 Prudential Norms
                  Directions.
                </p>

                <table className='table table-hover table-striped table-bordered'>
                  <thead className='bg-info text-white'>
                    <tr className='text-center'>
                      <td>
                        <strong>S.No</strong>
                      </td>
                      <td>
                        <strong>Compliance</strong>
                      </td>
                      <td>
                        <strong>Time Limit</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.</td>
                      <td>Unaudited March Monthly Return/NBS7</td>
                      <td>On or before 30th June</td>
                    </tr>
                    <tr>
                      <td>2.</td>
                      <td>Audited March Monthly Return</td>
                      <td>Upon Completion</td>
                    </tr>
                    <tr>
                      <td>3.</td>
                      <td>
                        Statutory Auditors Certificate on Income and Assets
                      </td>
                      <td>On or before 30th June</td>
                    </tr>
                    <tr>
                      <td>4.</td>
                      <td>Information about Cos having FDI/Foreign Funds</td>
                      <td>On or before 30th June</td>
                    </tr>
                    <tr>
                      <td>5.</td>
                      <td>Resolution of Non-acceptance of Public Deposit</td>
                      <td>Before the commencement of the new Financial year</td>
                    </tr>
                    <tr>
                      <td>6.</td>
                      <td>
                        Declaration of Auditors to Act as Auditors of the
                        Company
                      </td>
                      <td>Annual basis</td>
                    </tr>
                    <tr>
                      <td>7.</td>
                      <td>File Audited Annual Balance Sheet and P&L Account</td>
                      <td>One month from the date of sign-off</td>
                    </tr>
                  </tbody>
                </table>

                <h3 className='mt-5 mb-3'>
                  <strong>
                    Monthly Compliances of NBFC-ND(Non-Deposit taking)
                  </strong>
                </h3>
                <table className='table table-hover table-striped table-bordered'>
                  <thead className='bg-info text-white'>
                    <tr className='text-center'>
                      <td>
                        <strong>S.No</strong>
                      </td>
                      <td>
                        <strong>Compliance</strong>
                      </td>
                      <td>
                        <strong>Time Limit</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.</td>
                      <td>Monthly Return</td>
                      <td>by 7th of every month</td>
                    </tr>
                    <tr>
                      <td>2.</td>
                      <td>Upload monthly</td>
                      <td>by 7th of every month</td>
                    </tr>
                  </tbody>
                </table>
                <h3 className='mt-5 mb-3'>
                  <strong>
                    Periodical Compliances of NBFC-ND(Non-Deposit taking)
                  </strong>
                </h3>
                <table className='table table-hover table-striped table-bordered'>
                  <thead className='bg-info text-white'>
                    <tr className='text-center'>
                      <td>
                        <strong>S.No</strong>
                      </td>
                      <td>
                        <strong>Compliance</strong>
                      </td>
                      <td>
                        <strong>Time Limit</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.</td>
                      <td>Appointment of Director(Annexure-III)</td>
                      <td>within 30 days of appointment</td>
                    </tr>
                    <tr>
                      <td>2.</td>
                      <td>Upload monthly return</td>
                      <td>within 30 days of appointment</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>

          <ContentImg CISection_data={NbfcCiData} />

          <Guidance />

          <Cta />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
